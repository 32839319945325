.card {
  transition: box-shadow 0.3s ease;
  will-change: box-shadow;
  position: relative;
  height: 100%;
}

.card:hover {
  box-shadow: 2px 1px 6px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) -14px -4px 15px -5px, rgba(0, 0, 0, 0.05) 1px 15px 7px -5px;
}

.cardContent {
  height: 100%;
  justify-content: space-between;
}

.bodyWrapper {
  width: 100%;
}

.externalLink {
  pointer-events: none;
  filter: drop-shadow(0 1px 4px #000);
  position: absolute;
  cursor: pointer;
  z-index: 1;
  right: 24px;
  top: 24px;
}

.floatingTag {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 24px;
  transform: translateX(-50%);
}

.avaxIcon {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #e84142;
  overflow: hidden;
}

.buttonWrapper {
  width: 100%;
}
