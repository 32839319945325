.walletChip {
  position: relative;
}

.walletChip::before {
  content: "";
  width: 28px;
  height: 28px;
  position: absolute;
  z-index: -1;
  left: -15px;
  top: 0;
}

.walletAvatar {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

@media (max-width: 525px) {
  .logoImg {
    width: 200px;
    margin-left: -45px;
  }

  .logoImg img {
    width: 100% !important;
  }
}
